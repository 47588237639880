export default () => {
  const container = document.querySelector(".js-toggle");
  if (!container) return;
  const trigger = container.querySelector(".js-toggle-trigger");
  const hidden = container.querySelector(".js-toggle-hidden");

  trigger.addEventListener("click", () => {
    trigger.style.display = "none";
    hidden.classList.remove("hidden");
  });
};
