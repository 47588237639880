import cheerio from "cheerio";
import { debounce } from "lodash";
import toggle from "./toggle";

const addListeners = () => {
  toggle();
  const grid = document.querySelector(".js-projects");
  const moreLink = document.querySelector(".js-projects-more");
  if (!grid || !moreLink) return;
  moreLink.addEventListener("click", async (e) => {
    e.preventDefault();
    const data = await (await fetch(moreLink.href)).text();
    const $ = cheerio.load(data);
    const nextProjects = $(".js-projects");
    const nextMoreLink = $(".js-projects-more");

    const template = document.createElement("template");
    template.innerHTML = $(nextProjects).html();
    const childNodes = template.content.childNodes;
    grid.append(...childNodes);

    if (nextMoreLink.length === 0) {
      moreLink.parentNode.removeChild(moreLink);
    } else {
      moreLink.setAttribute("href", nextMoreLink.attr("href"));
    }
  });
};

export default () => {
  const target = document.getElementById("projects");
  const grid = document.querySelector(".js-projects");
  const moreLink = document.querySelector(".js-projects-more");

  if (!target || !grid || !moreLink) return;

  addListeners();
  const callback = debounce(addListeners, 250);
  const observer = new MutationObserver(callback);
  observer.observe(target, {
    attributes: true,
    attributeFilter: ["busy"],
  });
};
