import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

export default () => {
  const menu = document.querySelector(".js-menu");
  const menuOpen = document.querySelector(".js-menu-open");
  const menuClose = document.querySelector(".js-menu-close");

  menu.classList.remove("is-visible");
  clearAllBodyScrollLocks();

  menuOpen.addEventListener("click", () => {
    menu.classList.add("is-visible");
    disableBodyScroll(menu);
  });

  menuClose.addEventListener("click", () => {
    menu.classList.remove("is-visible");
    enableBodyScroll(menu);
  });
};
