import anime from "animejs";

export default async () => {
  const $el = document.querySelector(".js-home-animation");
  if (!$el) return;

  await anime({
    targets: $el,
    translateX: "100%",
    delay: 900,
    easing: "easeInQuad",
    duration: 300,
  }).finished;

  $el.parentNode.removeChild($el);
};
