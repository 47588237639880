import anime from "animejs";

export default () => {
  const arrowLinks = document.querySelectorAll(".js-arrow-link");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          anime({
            targets: target,
            opacity: 1,
            easing: "easeOutQuad",
            duration: 600,
            delay: 600,
          });
        }
      });
    },
    {
      threshold: 1.0,
    }
  );
  arrowLinks.forEach((arrow) => observer.observe(arrow));
};
