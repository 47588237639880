import tippy, { followCursor } from "tippy.js";

export default () => {
  tippy("[data-tippy-content]", {
    placement: "bottom-start",
    followCursor: true,
    plugins: [followCursor],
    offset: [16, 16],
  });
};
