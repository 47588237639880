import anime from "animejs";

export default () => {
  const arrows = document.querySelectorAll(".svg-arrow");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          anime({
            targets: target.querySelectorAll("stop"),
            offset: 1,
            easing: "easeOutQuad",
            duration: 300,
            delay: 300,
          });
        }
      });
    },
    {
      threshold: 1.0,
    }
  );
  arrows.forEach((arrow) => observer.observe(arrow));
};
