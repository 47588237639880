import anime from "animejs";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    return anime({
      targets: this.element,
      opacity: [0, 1],
      easing: "easeOutQuad",
      duration: 2000,
      delay: 250,
    });
  }
}
