import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([Pagination, Autoplay]);

export default () => {
  const $el = document.querySelector(".js-swiper");
  if (!$el) return;
  new Swiper($el, {
    pagination: {
      el: ".js-swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: { delay: 3000 },
    speed: 900,
    loop: true,
  });
};
