export default () => {
  const typeformLinks = document.querySelectorAll(".js-typeform-popup");

  typeformLinks.forEach((typeformLink) => {
    const typeformUrl = typeformLink.href;
    if (!typeformUrl) return;
    let popup;
    popup = window.typeformEmbed.makePopup(typeformUrl, {
      hideHeaders: true,
      hideFooter: true,
    });
    typeformLink.addEventListener("click", (e) => {
      e.preventDefault();
      popup.open();
    });
  });
};
