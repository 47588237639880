import Player from "@vimeo/player";

export default () => {
  const vimeoIframes = document.querySelectorAll(".js-vimeo");

  vimeoIframes.forEach((iframe) => {
    const container = iframe.parentElement;
    const player = new Player(iframe);

    container.addEventListener("mouseover", () => {
      player.play();
    });
    container.addEventListener("mouseleave", () => {
      player.pause();
    });
  });
};
