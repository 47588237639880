import "lazysizes";
import "whatwg-fetch";
import "@hotwired/turbo";

import { Application } from "stimulus";
import Controller from "./controller";
const app = Application.start();
app.register("transitions", Controller);

import swiper from "./swiper";
import arrowAnimations from "./arrowAnimations";
import arrowLinkAnimations from "./arrowLinkAnimations";
import mobileMenu from "./mobileMenu";
import collapsibles from "./collapsibles";
import typeform from "./typeform";
import homeAnimation from "./homeAnimation";
import projects from "./projects";
import vimeo from "./vimeo";
import tooltips from "./tooltips";
import toggle from "./toggle";

document.addEventListener("turbo:load", () => {
  homeAnimation();
  swiper();
  collapsibles();
  mobileMenu();
  arrowAnimations();
  arrowLinkAnimations();
  typeform();
  projects();
  vimeo();
  tooltips();
  toggle();
});
