import anime from "animejs";

export const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

const closeCollapsible = async (collapsible) => {
  const trigger = collapsible.querySelector(".js-collapsible-trigger");
  const content = collapsible.querySelector(".js-collapsible-content");
  const icon = collapsible.querySelector(".js-collapsible-icon");

  anime({ targets: icon, rotate: "0deg" });
  await anime({
    targets: content,
    height: 0,
    easing: "easeOutQuad",
    duration: 350,
  }).finished;
  collapsible.classList.remove("is-active");
  trigger.style.borderColor = "black";
};

const openCollapsible = (collapsible) => {
  const trigger = collapsible.querySelector(".js-collapsible-trigger");
  const content = collapsible.querySelector(".js-collapsible-content");
  const icon = collapsible.querySelector(".js-collapsible-icon");
  const ruler = collapsible.querySelector(".js-collapsible-ruler");
  const shouldHideBorders = trigger.classList.contains("js-hide-border");

  anime({
    targets: content,
    height: ruler.clientHeight,
    easing: "easeOutQuad",
    duration: 350,
  });
  anime({ targets: icon, rotate: "45deg" });
  collapsible.classList.add("is-active");
  if (shouldHideBorders) {
    trigger.style.borderColor = "transparent";
  }
  if (isTouchDevice()) {
    setTimeout(() => {
      trigger.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 350);
  }
};

export default () => {
  const collapsibles = document.querySelectorAll(".js-collapsible");
  collapsibles.forEach((collapsible) => {
    const trigger = collapsible.querySelector(".js-collapsible-trigger");

    trigger.addEventListener("click", async () => {
      const activeCollapsibles = document.querySelectorAll(
        ".js-collapsible.is-active"
      );
      const isActive = collapsible.classList.contains("is-active");

      if (isActive) {
        closeCollapsible(collapsible);
      } else {
        await activeCollapsibles.forEach(closeCollapsible);
        openCollapsible(collapsible);
      }
    });
  });
};
